import CrmDate from "../components/crm_date.vue";
import CrmSelect from "../components/crm_select.vue";
import CrmCheckbox from "../components/crm_checkbox.vue";

export function useFormControl(
  picklistValues,
  fieldRequiredText,
  minLengthText
) {
  function formControl(fld) {
    switch (fld.type) {
      case "picklist":
      case "multipicklist":
        return CrmSelect;
      case "checkbox":
        return CrmCheckbox;
      case "date":
      case "birthday":
        return CrmDate;
      default:
        return "q-input";
    }
  }

  function fldProps(fld) {
    switch (fld.type) {
      case "picklist":
      case "multipicklist":
        return {
          options: picklistValues[fld.picklist_id],
          emitValue: true,
          mapOptions: true,
          multiple: fld.type === "multipicklist",
        };
      case "textarea":
        return {
          type: "textarea",
        };
      case "email":
        return {
          type: "email",
        };
      default:
        return {};
    }
  }

  function requiredFieldValidator(fld) {
    return (val) => !fld.mandatory || !!val || val === 0 || fieldRequiredText;
  }

  function minLengthValidator(fld) {
    return (val) =>
      !fld.min_length ||
      !val ||
      val.length >= fld.min_length ||
      minLengthText.replace("%{count}", fld.min_length);
  }

  function formControlProps(fld, instance) {
    return {
      modelValue: instance[fld.name],
      "onUpdate:modelValue": (v) => (instance[fld.name] = v),
      label: fld.label,
      hideBottomSpace: true,
      filled: true,
      rules: [requiredFieldValidator(fld), minLengthValidator(fld)],
      ...fldProps(fld),
    };
  }

  return { formControl, formControlProps };
}
